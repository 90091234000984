import axios from "@/shared/axios";
import { BaseResponse, PageResponse } from "@/types/web.interface";

// 内容列表
export async function contentPage(params?: any) {
  const res = await axios.get<PageResponse<any>>("/cobra/content/page", {
    params,
  });
  return res.data;
}

// 获取Meta信息
export async function getMetadata(params?: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/metadata", {
    ...params,
  });
  return res.data;
}

// 根据内容编号获得明细
export async function getContentById(id: string) {
  const res = await axios.get<BaseResponse<any>>(
    `/cobra/content/content/${id}`,
  );
  return res.data;
}

// 添加内容信息
export async function saveContent(params: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/content", {
    ...params,
  });
  return res.data;
}

// 添加内容信息
export async function deleteContent(contentId: string) {
  const res = await axios.delete<BaseResponse<any>>(
    `/cobra/content/content/${contentId}`,
    {},
  );
  return res.data;
}

// 开始监听
export async function startMonitor(params: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/monitor", {
    ...params,
  });
  return res.data;
}

// 提起下架申请
export async function takedown(params: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/takedown", {
    ...params,
  });
  return res.data;
}

// 手动上传线索
export async function addOwnClew(params: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/addClew", {
    ...params,
  });
  return res.data;
}

// 内容线索列表
export async function clewPage(id: string, params: any) {
  const res = await axios.get<PageResponse<any>>(
    `/cobra/content/content/${id}/clewPage`,
    {
      params,
    },
  );
  return res.data;
}

// 获取线索详情
export async function getClewDetail(id: string) {
  const res = await axios.get<BaseResponse<any>>(`/cobra/content/clew/${id}`);
  return res.data;
}

// 上传图片
export async function uploadImage(file: File) {
  if (file === null) {
    return {
      code: 0,
      message: "",
      data: null,
    };
  }
  const formData = new FormData();
  formData.append("image", file);
  try {
    const res = await axios.post<BaseResponse<any>>(
      `/cobra/content/upload/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return res.data;
  } catch (error) {
    return {
      code: -1,
      message: "error",
    };
  }
}

// 添加反馈
export async function feedback(params: any) {
  const res = await axios.post<BaseResponse<any>>("/survey/feedback", {
    ...params,
  });
  return res.data;
}

// 重新开启监控

export async function resStartMonitorApi(params: any) {
  const res = await axios.post<BaseResponse<any>>("/cobra/content/reactivate", {
    ...params,
  });
  return res.data;
}
